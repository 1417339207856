<template>
  <div
    class="bg-beige min-h-screen flex flex-col items-center justify-center text-center"
  >
    <h1 class="text-h2 font-bold font-Kontesa text-shadow text-white mb-6">
      {{ error.statusCode }}
    </h1>
    <p
      class="text-2xl font-medium font-Kontesa leading-tight text-black mb-6 text-balance"
      v-if="error.statusCode === 404"
    >
      <template v-if="error.statusCode === 404">
        Oops <br />We couldn't find the page you were looking for!
      </template>
      <template v-else>
        {{ error.statusMessage ?? "An error occurred" }}
      </template>
    </p>

    <button
      class="text-[24px] leading-none text-[#FFFFF6] px-6 py-3 font-Kontesa font-bold bg-black rounded-lg"
      @click="handleError"
    >
      Go back home
    </button>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from "#app";

defineProps<{
  error: NuxtError;
}>();

const handleError = () => clearError({ redirect: "/" });
</script>
